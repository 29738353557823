import React, {useState} from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import {
  Row,
  Col,
  Typography,
  Button, Form, Input
} from 'antd'
import { Link, Redirect } from 'react-router-dom'
import {CopyOutlined} from "@ant-design/icons";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import UpdateDetails from "../../Common/ApiCalls/MainBackend/UpdateDetails";

const {Title} = Typography

const Homepage = ({user, refresh}: any) => {
  const [f] = Form.useForm()
  const [l ,setL] = useState(false)

  const cp = `<div id="ib-feed"></div>
<script>
  (function (w,d,s,o,f,js,fjs) {
    w['IB-IG-Feed']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', 'ib_feed', 'https://ibinstafeed.netlify.app/widget.min.js'));
(function initIBFeed() {ib_feed('init', { brand_id: ${user.id}});}());
</script>`

  const submit = async () => {
    setL(true)
    const b = f.getFieldsValue()
    const res = await UpdateDetails(b)
    setL(false)
    refresh()
    if(res.success) {
      SuccessNotif("Updated")
    } else {
      BadNotif(res)
    }
  }

  return (
    <ContentWrapper>
      <Form
        form={f}
        initialValues={user}
        onFinish={() => submit()}
      >
        <Form.Item
          noStyle
        >
          Instagram Username
          <Form.Item
            name={"ig_username"}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Heading
          <Form.Item
            name={"heading"}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          {() => {
            const a = {
              ig_username: user.ig_username,
              heading: user.heading,
            }
            const b = f.getFieldsValue()
            return (
              <Button
                htmlType={"submit"}
                hidden={JSON.stringify(a) === JSON.stringify(b)}
                disabled={JSON.stringify(a) === JSON.stringify(b) || l}
              >
                {l ? "Updating..." : "Save"}
              </Button>
            )
          }}
        </Form.Item>
      </Form>
      <div className={"box"}>
        <p className={"subtitle"}>
          Embed
          <Button
            className={"mx-3 is-pulled-right"}
            icon={<CopyOutlined />}
            title={"Copy Embed Code"}
            onClick={() => {
              navigator.clipboard.writeText(cp)
              SuccessNotif("Code Copied to Clipboard")
            }}
          >
            Copy Code
          </Button>
        </p>
        <div className={"highlight"}>
          <pre>
            <code>
              {cp}
            </code>
          </pre>
        </div>
      </div>
    </ContentWrapper>
  )

  return (
    <ContentWrapper
    >
      <Row
        align='middle'
        gutter={24}
        justify='center'
      >
        <Col
          md={{span: 20}}
        >
          <Title
            level={3}
            className="has-text-black"
          >
            Welcome
          </Title>
          <Link
            to="/connect_fb"
          >
            Connect/View Fb
          </Link>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default Homepage