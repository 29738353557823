import { useState } from 'react'
import {
  Button,
  Card,
  Form,
  Input
} from 'antd'
import LoginUser from '../Common/ApiCalls/MainBackend/LoginUser'
import { setToken } from '../Common/ApiCalls/Logout'
import { BadNotif } from '../Common/Utils/SendNotification'
import { Link } from 'react-router-dom'

const Login = ({
  setUser
}: any) => {
  const [load, setLoad] = useState(false)

  const userLogin = async (values: any) => {
    setLoad(true)
    const r = await LoginUser(values.email, values.password)
    setLoad(false)
    if (r.success) {
      setToken(r.data.token)
      setUser()
    } else {
      BadNotif(r)
    }
  }

  return (
    <Card
      title="Login"
    >
      <Form
        onFinish={userLogin}

      >
        <Form.Item
          noStyle
        >
          Email
          <Form.Item
            name="email"
          >
            <Input
              placeholder="joe@gmail.com"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Password
          <Form.Item
            name="password"
          >
            <Input.Password
              type='password'
              placeholder="your password here"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          shouldUpdate
        >
          <Button
            htmlType='submit'
            type='primary'
            size='large'
            disabled={load}
          >
            {load  ? "Wait..." : "Submit"}
          </Button>
        </Form.Item>
        <Form.Item>
          Don't have an account? <Link to="/register">Register</Link>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Login
