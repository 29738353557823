export const ApiConstats = {
  PROD_SERVER_URL: 'https://ibfeed.influencerbit.com/api/ibfeed/v1/',
  DEV_SERVER_URL: 'http://localhost:3000/api/ibfeed/v1/',
  RUN_PROD_SERVER: true,
  LOCALHOST_SERVER: process.env.NODE_ENV === 'development',
  DEV_DOMAIN: 'localhost',
  PROD_DOMAIN: '.influencerbit.com',
  AUTH_COOKIE_NAME: 'ibFeedToken',
  AUTH_COOKIE_PATH: "/"
}

export function ServerURL(url = '') {
  return (ApiConstats.LOCALHOST_SERVER ? ApiConstats.DEV_SERVER_URL : ApiConstats.PROD_SERVER_URL) + url
}