import NotificationTypeEnum from "../Models/NotificationTypeEnum";
import {message} from "antd";

function SendNotification(title: string, messageShow: string, isLong=false) {
    let duration = isLong ? 20 : 5;
    if (title === NotificationTypeEnum.Failure) {
        message.error(messageShow, duration)
    } else {
        message.success(messageShow, duration)
    }
}

export function BadNotif(r: any, message = "Something Wrong Happened") {
    if(r && r.errors) {message = r.errors[0].message}
    SendNotification(NotificationTypeEnum.Failure, message)
}

export function SuccessNotif(message = 'Done') {
    SendNotification(NotificationTypeEnum.Success, message)
}

export default SendNotification;